import React from "react";
import backgroundImage from "images/banners/bg-experiment-capabilities.jpg";
import styled from "styled-components";
import { BannerTitle, Layout, PageContainer, SEO } from "components";
import { Col, Row } from "reactstrap";

const ExperimentsPage = ({ location }) => {
  const title = "Experimental Capabilities";

  return (
    <Layout clearNav location={location}>
      <SEO image={backgroundImage} title={title} uri={location.pathname} />
      <BannerTitle image={backgroundImage}>{title}</BannerTitle>
      <PageContainer>
        <StyledRow>
          <StyledCol xs={12} md={12} lg={9}>
            <Title>
              Remotely control a complete life sciences laboratory from your
              computer
            </Title>
            <p>
              Emerald's Command Center software puts you at the helm of a vast
              ECL facility where you can orchestrate as many experiments in
              parallel as you'd like while sitting comfortably at your computer
              from anywhere in the world. Our goal is to provide you with full
              control over all common in-vitro life science experiments with an
              ever-expanding capabilities list.
            </p>
          </StyledCol>
        </StyledRow>
      </PageContainer>
    </Layout>
  );
};

export default ExperimentsPage;

const StyledRow = styled(Row)`
  margin-top: 2.5rem;
`;

const StyledCol = styled(Col)`
  margin-bottom: 1.7rem;
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  font-weight: 100;
`;
